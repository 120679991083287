import React,{useState} from 'react'
import '../Dashboard/css/DataTable.css';
function DataTable() {
  const [sidebarToggled, setSidebarToggled] = useState(false);
  return (


    <main className="app-content background">
  
   
    </main>
  )
}

export default DataTable